/*----------------------------------------------------------------------------*/
/*  #PRIMARY NAVIGATION                                                       */
/*----------------------------------------------------------------------------*/
.primary-navigation {
  background: var(--color_white-0);
  display: none;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: left 0.5s ease, box-shadow 0.3s ease;
  z-index: 999;
  @media (min-width: 52.5rem) {
    background: var(--color_white-0);
    padding: .6rem 0 1.125rem;
    margin: 0;
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    display: block;
  }
  &__toggle {
    color: var(--color_blue-0);
    display: inline-block;
    padding: 0.5rem;
    width: 0.8rem;
    @media (min-width: 52.5rem) {
      display: none;
    }
    &:hover, 
    &:focus {
      color: var(--color_black-0);
    }
  }
  &__toggle-icon {
    display: inline-block;
    line-height: 1;
    width: 0.8rem;
    &:before {
      content: url(/assets/graphics/bars.svg)
    }
  }
  &__wrapper {
    background: var(--color_white-0);
    margin: 0 auto;
    padding: 0;
    @media (min-width: 52.5rem) {
      padding: 0 5%;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    min-height: 100%;
    padding: 2.2rem 0 0;
    width: auto;
    @media (min-width: 52.5rem) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background: none;
      display: flex;
      height: auto;
      list-style: none;
      line-height: .8em;
      margin: 0;
      padding: 0;
      width: auto;
    }
  }
  &__list-item {
    &:first-child a {
      border-top: 0.004rem solid var(--color_black-0);
    }
    @media (min-width: 52.5rem) {
      display: inline;
      padding: 0 .938rem 0 0;
      &:last-child {
        padding: 0;
      }
    }
  }
  &__anchor {
    background: var(--color_blue-0);
    border-bottom: 0.004rem solid var(--color_white-0);
    color: var(--color_white-0);
    font-family: var(--font_500);
    font-size: var(--font-size_s);
    display: block;
    padding: .75rem;
    letter-spacing: 0.013rem;
    line-height: 1rem;
    text-decoration: none;
    transition: all .3s;
    &:active,
    &:hover,
    &:focus {
      background: var(--color_white-0);
      border-bottom: 0.004rem solid var(--color_black-0);
      color: var(--color_black-0);
      text-decoration: none;
    }
    @media (min-width: 52.5rem) {
      background: none;
      border: 0 !important;
      color: var(--color_blue-0);
      font-size: var(--font-size_l);
      letter-spacing: 0.013rem;
      padding: .5rem 0 .2rem;
      text-decoration: none;
      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
    &--active {
      background: var(--color_white-0);
      color: var(--color_black-0);
      @media (min-width: 52.5rem) {
        text-decoration: underline;
      }
      &:focus,
      &:hover {
        background: var(--color_white-0);
        color: var(--color_black-0);
      }
    }
  }
  &__menu-close {
    color: var(--color_black-0);
    position: absolute;
    right: 1.5rem;
    top: 0.55rem;
    width: 0.8rem;
    @media (min-width: 52.5rem) {
      display: none;
    }
  }
  &__menu-close-icon {
    display: inline-block;
    line-height: 1;
    width: 0.8rem;
    &:before {
      content: url(/assets/graphics/times.svg)
    }
  }
  &:target, 
  &[aria-expanded="true"] {
    background: var(--color_white-0);
    display: block;
    left: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
  }
  &:target .primary-navigation__menu-close, 
  &[aria-expanded="true"] .primary-navigation__menu-close {
    z-index: 1001;
  }
  &:target ul, 
  &[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }
  &:target + .primary-navigation__backdrop, 
  &[aria-expanded="true"] + .primary-navigation__backdrop {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    cursor: default;
  }
}
@supports (position: fixed) {
  .primary-navigation {
    position: fixed;
    @media (min-width: 52.5rem) {
      position: relative;
    }
    &:target + .primary-navigation__backdrop, 
    &[aria-expanded="true"] + .primary-navigation__backdrop {
      position: fixed;
      @media (min-width: 52.5rem) {
        position: relative;
      }
    }
  }
}

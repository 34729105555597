/*----------------------------------------------------------------------------*/
/*  #IMPORTS - from /_sass/                                                   */
/*----------------------------------------------------------------------------*/
@import "accessibility";                    /* _sass/_accessibility.scss      */
@import "boxes";                            /* _sass/_boxes.scss              */
@import "buttons";                          /* _sass/_buttons.scss            */
@import "colors";                           /* _sass/_colors.scss             */
@import "error-pages";                      /* _sass/_error-pages.scss        */
@import "font-sizes";                       /* _sass/_font-sizes.scss         */
@import "gradients";                        /* _sass/_gradients.scss          */
@import "language-switch";                  /* _sass/_language-switch.scss    */
@import "lists";                            /* _sass/_lists.scss              */
@import "primary-navigation";               /* _sass/_primary-navigation.scss */
@import "sitemap";                          /* _sass/_sitemap.scss            */
@import "slider";                           /* _sass/_slider.scss             */
@import "tables";                           /* _sass/_tables.scss             */
@import "typefaces";                        /* _sass/_typefaces.scss          */





/*----------------------------------------------------------------------------*/
/*  # WRAPPER                                                                 */
/*----------------------------------------------------------------------------*/
.wrapper {
  margin: 0 auto;
  padding: 0 5%;
}





/*----------------------------------------------------------------------------*/
/*  # DEFAULTS                                                                */
/*----------------------------------------------------------------------------*/
html {
  background:  var(--color_white-0);
  height: 100%;
}

body {
  background: var(--color_white-0);
  color: var(--color_black-0);
  font-family: var(--font_300);
  font-weight: normal;
  margin: 0 auto;
}





/*----------------------------------------------------------------------------*/
/*  # HEADINGS                                                                */
/*----------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font_400);
  font-weight: normal;
}

h1 {
  color: var(--color_blue-0);
  font-family: var(--font_400);
  font-size: var(--font-size_xxxl); 
}

h2 {
  color: var(--color_black-0);
  font-family: var(--font_400);
  font-size: var(--font-size_xxl); 
  margin: 0 0 2.4rem;
}

h3 {
  color: var(--color_black-0);
  font-family: var(--font_400);
  font-size: var(--font-size_l); 
}

h4, h5, h6 {
  color: var(--color_black-0);
  font-family: var(--font_400);
  font-size: var(--font-size_large); 
}





/*----------------------------------------------------------------------------*/
/*  # TEXT-LEVEL                                                              */
/*----------------------------------------------------------------------------*/
main p {
  font-family: var(--font_300);
  font-size: var(--font-size_m);
  margin: 0 0 1rem;
  &.doctor {
    margin: 0 0 2.4rem;
  }
}

b,
strong,
th {
  font-family: var(--font_400);
  color: var(--color_black-0);
  font-weight: normal;
  i,em {
    font-family: var(--font_400italic);
  }
}


cite,
em,
i {
  font-family: var(--font_300italic);
  font-style: normal;
}

a {
  color: var(--color_blue-0);
  text-decoration: none;
  overflow-wrap: break-word;
  &:hover,
  &:active,
  &:focus {
    color: var(--color_black-0);
    text-decoration: underline;
  }
}

hr {
  background: none;
  border: none;
  border-bottom: 0.004rem solid var(--color_grey-2);
  margin: 2.5rem 0;
}





/*----------------------------------------------------------------------------*/
/*  #HEADER                                                                  */
/*----------------------------------------------------------------------------*/
.website-header {
  background: var(--color_white-0);
  margin: 0;
  padding: 0.938rem 0 2.5rem;
  display: flex;
  align-items: baseline;

  h1 {
    margin: 0;

    a,
    &--logo:link,
    &--logo:visited, 
    &--logo:hover {
      border: none;
      color: var(--color_black-0);
      display: block;
      line-height: 0;
      text-decoration: none;
    }
  }
}





/*----------------------------------------------------------------------------*/
/*  # IMAGES                                                                  */
/*----------------------------------------------------------------------------*/
.mood-image {
  border-top: .004rem solid var(--color_blue-0);
  margin: 0 0 1rem 0;
  width: 100%;
}
.content-image {
  max-width: 100%;
}





/*----------------------------------------------------------------------------*/
/*  # GOOD NEWS                                                              */
/*----------------------------------------------------------------------------*/
.good-news {
  border: .07rem solid var(--color_black-0);
  border-radius: 1rem;
  margin: 2rem 0 0;
  padding: 0 1.2rem 1.2rem;
}





/*----------------------------------------------------------------------------*/
/*  # CONTACT FORM                                                            */
/*----------------------------------------------------------------------------*/
.contact-form {
  margin: 2rem 0 0 0;
  width: 40%;
  @media (min-width: 37.5rem) {
    width: 80%;
  }
  @media (max-width: 37.5rem) {
    width: 100%;
  }
  @media (min-width: 50rem) {
    width: 50%;
  }
  button {
    margin: 0.2rem 0 1rem 0;
  }
  input {
    box-sizing: border-box;
    font-family: var(--font_300);
    font-size: var(--font-size_xs);
    margin: 0.2rem 0 1rem 0;
    padding: 0.2rem 0.4rem 0.2rem 0.3rem;
    width: 100%;
  }
  label {
    box-sizing: border-box;
    font-family: var(--font_400);
    font-size: var(--font-size_xs);
    padding: 0 .4rem 0 0;
    display: block;
    width: 100%;
    span {
      color: var(--color_grey-5);
      font-family: var(--font_300);
      font-size: var(--font-size_xxs);
    }
  }
  .salutation {
    display: inline-block;
    list-style: outside none;
    margin: 0.2rem 0 1rem 0;
    padding: 0;
    width: 100%;
    &::before {
      clear: both;
    }
    &__item {
      float: left;
      padding: .2rem .4rem;
      &::before {
        content: none;
      }
      input[type="radio"] {
        align-items: center;
        display: inline-flex;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        width: auto;
      }
      label {
        display: inline;
        font-family: var(--font_400);
        font-size: var(--font-size_xs);
        margin: 0;
        padding: 0;
        vertical-align: middle;
        width: auto;
      }
    }
  }
  select {
    box-sizing: border-box;
    font-family: var(--font_300);
    font-size: var(--font-size_xxs);
    margin: 0.2rem 0 1rem 0;
    width: 100%;
  }
  textarea {
    box-sizing: border-box;
    font-family: var(--font_300);
    font-size: var(--font-size_xs);
    height: 6rem;
    width: 100%;
    margin: 0.2rem 0 1rem 0;
    padding: 0.2rem 0.4rem 0.2rem 0.3rem;
  }
  .privacy-notice {
    font-family: var(--font_300);
    font-size: var(--font-size_xxs);
    line-height: 1rem;
  }
}





/*----------------------------------------------------------------------------*/
/*  # DEFAULTS                                                                */
/*----------------------------------------------------------------------------*/
.glossary {
  dt {
    font-family: var(--font_400);
    font-size: var(--font-size_l);
  }
  dd {
    font-family: var(--font_300);
    margin: 0 0 1.4rem;
  }
}





/*----------------------------------------------------------------------------*/
/*  # FOOTER                                                                  */
/*----------------------------------------------------------------------------*/
.website-footer {
  background: var(--color_grey-3);
  font-size: var(--font-size_s);
  margin: 3.5rem 0 0;
  padding: 0;
  &:before {
    clear: both;
    content: " ";
  }
}

.website-footer-navigation {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 40%;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 2.5rem;

  &__flex-items {
    flex-basis: 10rem;
    flex-grow: 1;
    padding: 0 1rem;
    text-align: center;

    &:first-of-type {
      padding: 0 1rem 0 0;

      @media (max-width: 35rem) {
        padding: 0 1rem;
      }
    }

    &:last-of-type {
      padding: 0 0 0 1rem;
      @media (max-width: 35rem) {
        padding: 0 1rem;
      }
    }

    @media (max-width: 35rem) {
      padding: 0 1rem;
    }
  }

  &__heading {
    border-bottom: 0.01rem dotted var(--color_grey-2);
    color: var(--color_white-0);
    font-family: var(--font_500);
    font-size: var(--font-size_xs);
    font-weight: normal;
    text-align: left;
    margin: 2.5rem 0 .8rem;
    padding: 0 0 .6rem;
  }

  ul,
  &__list {
    list-style: outside none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    font-size: var(--font-size_xxs);
    margin: 0;
    padding: 0;
    text-align: left;
  }

  a,
  &__anchor {
    font-family: var(--font_300);
    color: var(--color_white-0);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: var(--color_white-0);
      text-decoration: underline;
    }
  }
}

.website-footer-bottom {
  background: var(--color_grey-4);
  color: var(--color_white-0);
  padding: .8rem 0 1rem;

  &__copyright {
    font-family: var(--font_500);
    font-size: var(--font-size_xxs);
  }

  &__list {
    margin: 0;
    padding: 0;
    li {
      display: inline;
      padding: 0 .625rem 0 0;
      &:last-child {
        padding: 0;
      }
      a,
      &__anchor {
        font-family: var(--font_300);
        font-size: var(--font-size_xxs);
        color: var(--color_white-0);
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
          color: var(--color_white-0);
          text-decoration: underline;
        }
      }
    }
  }
}
